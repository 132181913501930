import * as lottie from './lottie.min.js';
import data from './data.json';
var params = {
    container: document.getElementById('lottie'),
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: data
};
var anim;
anim = lottie.loadAnimation(params);
anim.addEventListener('DOMLoaded', function (e) {
    var firstAnimEndTime = 179;
    var secondAnimEndTime = 262;
    var segmentOne = [0, firstAnimEndTime];
    var segmentTwo = [firstAnimEndTime, secondAnimEndTime];
    anim.playSegments([segmentOne, segmentTwo], true);
});